
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import groupBy from 'lodash.groupby';
import giftCardService from '@/services/giftCards/giftCardService';
import IContracts from './interfaces/Contract/contract';

@Component
export default class ContractListAccepted extends Vue {
  public error = '';
  public loading = false;
  public contractsList: IContracts[] = [];

  async created(): Promise<void> {
    this.getContractList();
  }

  async getContractList(): Promise<void> {
    this.loading = true;
    try {
      const { data } = await giftCardService.getContractList(true);
      this.contractsList = data.contracts;
    } catch (error: any) {
      const { data } = error.response;
      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
    } finally {
      this.loading = false;
    }
  }

  get formatedData(): any {
    const formatedObject = groupBy(this.contractsList, (contract) => (
      moment(contract.acceptationDate).startOf('day').format()
    ));
    return formatedObject;
  }

  public goToContractLotCard(contract: IContracts): void {
    this.$router.push({
      path: '/contract-lot-card',
      // eslint-disable-next-line no-underscore-dangle
      query: { id: contract._id, contract: JSON.stringify(contract) },
    });
  }

  formatDate(date: string): string {
    return moment(date).format('DD MMMM Y').toString();
  }

  formatDateToHour(date: string): string {
    return moment(date).format('HH:MM').toString();
  }
}
