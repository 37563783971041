
import { Component, Vue } from 'vue-property-decorator';
// import TransactionsList from '@/components/TransactionsList.vue';
import ContractListAccepted from '@/components/ContractListAccepted.vue';
import ContractListPending from '@/components/ContractListPending.vue';

@Component({
  components: {
    ContractListPending,
    ContractListAccepted,
  },
})
export default class Contract extends Vue {
  public ongletSelectionned = 'ContractListPending';
}
